import { graphql } from "gatsby"
import React from "react"
import Nav from 'components/pages/Curriculum/components/Nav'
import Header from 'components/pages/Curriculum/components/Header'
import Content from 'components/pages/Curriculum/components/Content'
import SEO from 'components/SEO'
import Layout from "../layouts/Curriculum"

interface IProps {
  path: string
  data: {
    strapiCurriculumPages: Strapi.CurriculumPage,
    site: {
      siteMetadata: { siteUrl: string }
    }
  }
}

const CurriculumPageTemplate = (props: IProps) => {
  const category = props.data.strapiCurriculumPages.curriculum_category
  const page = props.data.strapiCurriculumPages

  const primary = category.Header?.bgGradient?.from

  const SEOProps = {
    title: page.SEO?.title || category.SEO?.title,
    titleTemplate: "%s | Night Zookeeper",
    image: page.SEO?.image?.url || category.SEO?.image?.url,
    url: page.SEO?.url || `${props.data.site.siteMetadata.siteUrl}${props.path}` || category.SEO?.url,
    canonical: page.SEO?.canonical || `${props.data.site.siteMetadata.siteUrl}${props.path}` || category.SEO?.canonical,
    description: page.SEO?.description || category.SEO?.description,
  }

  return (
    <Layout>
      <SEO {...SEOProps} titleTemplate='%s | Night Zookeeper' />
      <Nav items={[
        { slug: `${category.slug}`,
        title: `${category.title}`,
        to: `${category.customPath || ''}/${category.customSlug || category.slug}`},
        ...category.curriculum_pages.map(p => ({
          slug: p.slug,
          title: p.title,
          to: `${category.customPath || ''}/${category.customSlug || category.slug}/${p.customSlug || p.slug}`
        }))]} selected={page.slug} headerColor={primary || '#A5326C'} />
      <Header {...category.Header} {...(page.Header || {})} />
      <Content content={page.content} primary={primary} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiCurriculumPages(strapiId: { eq: $id }) {
      slug
      title
      content
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
      Header {
        title
        subTitle
        button {
          id
          value
          theme
          targetUrl
        }
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 660, placeholder: BLURRED)
            }
          }
        }
        bgGradient {
          from
          to
          angleDegree
        }
      }
      curriculum_category {
        id
        slug
        title
        customSlug
        customPath
        curriculum_pages(sort: { fields: slug, order: ASC }){
          id
          title
          customSlug
          slug
        }
        Header {
          title
          subTitle
          button {
            id
            value
            theme
            targetUrl
          }
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(width: 660, placeholder: BLURRED)
              }
            }
          }
          bgGradient {
            from
            to
            angleDegree
          }
        }
        SEO {
          title
          description
          url
          noIndex
          image {
            url
          }
          canonical
        }
      }
    }
  }
`

export default CurriculumPageTemplate
